<div class="row p-margin-top-2">
  <div [ngClass]=" step !== 7? 'col-6': 'col-4' ">
    <span *ngIf="patientInfo || step == 6">
      <button
        mat-button
        [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary btn-light' : 'btn-secondary btn-light'" 
        (click)="onHandlePrev(step)"
      >
        <fa-icon icon="arrow-left"></fa-icon>
        <span> Voltar</span>
      </button>
    </span>
  </div>

  <div *ngIf="step == 7" class="timerDiv col-4 "> Tempo para confirmação: <span [ngClass]=" redTimer? 'timer redFontTimer': 'timer' ">{{timerToShow}}</span></div>

  <div [ngClass]=" step !== 7? 'col-6': 'col-4' " align="right" *ngIf="step === 5 || step === 7">
    <button 
      mat-button 
      [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary' : 'btn-primary'"  
      (click)="onHandleNext(step)"
      [disabled]="settingVoucher || (isSchedulersView && !search?.paymentLink && step === 7 && search.price === FreeProcedure)"
    >

      <span *ngIf="!settingVoucher">{{ 
        this.step == 5 ? 'Próximo' : (search.price !== FreeProcedure && search.procedure.onlinePayment && accountInfo.onlinePayment ? 'Pagar' : 'Confirmar' )
      }} 
        <fa-icon *ngIf="step == 5" icon="arrow-right"></fa-icon>
        <fa-icon *ngIf="step == 7 && (search.price == FreeProcedure || (!search.procedure.onlinePayment && accountInfo.onlinePayment))" [icon]="['fas', 'check']"></fa-icon>
        <fa-icon *ngIf="step == 7 && (search.price !== FreeProcedure && search.procedure.onlinePayment && accountInfo.onlinePayment)" icon="dollar-sign"></fa-icon>
      </span>
      <span *ngIf="settingVoucher" >    
        <mat-spinner 
          [diameter]="25"
          color="primary">
        </mat-spinner>
      </span>
    </button>
  </div>
  <div *ngIf="step == 7" class="col-12 centerTxt"> Caso <b>saia da tela</b> ou o <b>tempo termine</b>, o agendamento reservado será removido</div>

</div>
