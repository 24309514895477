<h1 class="pre-confirmation-main-title">
  Confirmação do agendamento{{isSchedulersView? (' para ' + search.paciente.name.toUpperCase()) : ''}}
</h1>

<section class="pre-confirmation">
  <mat-card
  [ngClass]="
    isAmorSaude? 
    'pre-confirmation-title pre-confirmation-title-amorsaude-color mat-card mat-focus-indicator p-margin-bottom-2'
    : 'pre-confirmation-title pre-confirmation-title-default-color mat-card mat-focus-indicator p-margin-bottom-2'
  "
  >
    <time>
      {{ search.scheduleDate | date: 'dddd, DD/MM/YYYY':'DDMMYYYY' }} às
      {{ search.hour.startTime | date: 'HH:mm':'HHmm' }} (horário de Brasília)
    </time>

    <a
      [href]="search.local.website"
      *ngIf="search.procedure.teleconsulta"
      target="_blank"
    >
      {{ search.local.website }}
    </a>

    <address *ngIf="!search.procedure.teleconsulta">
      {{ search.local.name }} -
      <span>
        {{ search.local.address }}
      </span>
      {{ search.local.number | concat: ', ':true:true }}
      {{ search.local.neighbor | concat: ' - ':true:true }}
      <a [href]="getMapView(search.local)" target="_blank">
        Ver no mapa
      </a>
    </address>
  </mat-card>

  <mat-card class="pre-confirmation-content mat-card mat-focus-indicator">
    <div class="pre-confirmation-content-card" *ngIf="step == 7">
      <div class="pre-confirmation-content-card-title">
        <h2>
          {{ search.procedure.name }}
        </h2>
      </div>

      <div class="pre-confirmation-content-card-avatar">
        <img
          *ngIf="search.professionalPhoto"
          width="90"
          height="90"
          [src]="search.professionalPhoto"
          [alt]="search.professional"
          (error)="photoError(search)"
        />
        <app-avatar
          *ngIf="!search.professionalPhoto"
          [info]="search.professional"
          [size]="'large'"
          [ngClass]=" isAmorSaude?'amorsaude-bg-red p-margin-right-2':'bg-purple p-margin-right-2' "
        ></app-avatar>
      </div>

      <div class="pre-confirmation-content-card-info">
        <h2>{{ search.treatmentPronoun }} {{ search.professional }}</h2>
        <p>
          {{ search.occupation.name }}
          {{ search.expertise.name ? '(' + search.expertise.name + ')' : '' }}
        </p>
        <h4  [ngClass]=" isAmorSaude? 'amorsaudeColor' : 'purple'" *ngIf="search.document">
          {{ search.documentType }} {{ search.document }}
          <span *ngIf="search.rqe">RQE {{ search.rqe }}</span>
        </h4>
      </div>
      <div class="valueProcedure" *ngIf="isAmorSaude || (search.procedure.onlinePayment && accountInfo.onlinePayment && !isSchedulersView)">
        <p><strong>Valor do procedimento:</strong> R$ {{originalValue.replace('.', ',')}}</p>
        <p            
          *ngIf="applied"
          @fadeSlide
        >
          <strong>Valor do desconto:</strong> <span class="red"> -R$ {{discount.replace('.', ',')}}</span>
        </p>
        <p            
          *ngIf="applied"
          @fadeSlide
        >
          <strong>Total a pagar:</strong> R$ {{discountedValue.replace('.', ',')}}
        </p>
        <div class="voucherAndBtn" *ngIf="hasVouchers">
          <mat-form-field appearance="outline" class="formVoucher">
            <mat-label>Cupom de desconto</mat-label>
            <input
              [formControl]="voucherCode"
              matInput
              type="text"
              palceholder="Cupom de desconto"
              maxlength="{{ maxCodeLength }}"
              oninput="this.value = this.value.toUpperCase()"
              (keypress)="omitCharacters($event)"
              (input)="onChangeCode($event)"
            />
            <mat-error *ngIf="voucherCode.hasError('incorrect')">Um código precisa ser digitado</mat-error>
            <mat-error *ngIf="voucherCode.hasError('httpException')">{{ errorMsg }}</mat-error>
          </mat-form-field>
          <button
            *ngIf="!applied"
            mat-button 
            [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary' : 'btn-secondary'"
            (click)="onApplyVoucher()"
          >
            <span *ngIf="!loadingVoucher">Aplicar</span>
            <span *ngIf="loadingVoucher" >    
              <mat-spinner 
                [diameter]="25"
                color="primary">
              </mat-spinner>
            </span>
          </button>
          <button
            *ngIf="applied"
            @fadeSlide
            mat-button
            matTooltip="Cupom aplicado"
            matTooltipPosition="above" 
            class="success-green"
          >
            <fa-icon icon="check"></fa-icon>
          </button>
        </div>
      </div>

      <div *ngIf="isSchedulersView" class="uploadFile">
        <button 
          *ngIf="!!search?.paymentLink"
          mat-button 
          class="btn-secondary schedules"
          (click)="copyPaymentLink()"
        >
          <fa-icon icon="dollar-sign"></fa-icon> Link pagamento
        </button>

        <mat-spinner
          *ngIf="!search?.paymentLink"
          [diameter]="25"
          color="primary"
        >
        </mat-spinner>
      </div>
      <!-- <div *ngIf="isSchedulersView" class="uploadFile">
        <p>{{fileName}}</p>
        <button 
          mat-button
          type="button" 
          class="btn-secondary"
          (click)="inputUpload.click()"
          >
          <fa-icon [icon]="['fas', 'paperclip']" size="lg"></fa-icon> Adicionar anexo
        </button>
      
        <input
            #inputUpload
            class="input-upload"
            type="file"
            (change)="onClickUpload($event)"
            accept=".pdf, .png, .jpg, .jpeg"
        />
      </div> -->
    </div>

    <div class="consent-checkbox" *ngIf="step == 7 && accountInfo.showAgendaOnlineConsent && isTeleconsultationProcedure">
      <mat-checkbox
        [(ngModel)]="consentStatus"
        (change)="onChangeConsentCheckbox($event)"
      >
        Declaro que li e compreendi o presente <a class="consent-checkbox-link" (click)="openConsentDialog()">Termo de consentimento</a>
        <br>
        relacionado ao atendimento de teleconsulta
      </mat-checkbox>
    </div>

    <div class="pre-confirmation-content-card" *ngIf="step == 8">
      <h2 class="pre-confirmation-content-card-title-loading">
        Efetuando reserva...
      </h2>
      <app-loading [scale]="0.9"></app-loading>

      <ng-container *ngIf="error">
        {{ error }}
      </ng-container>
    </div>
  </mat-card>
</section>
