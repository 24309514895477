<div class="container-fluid">
  <div class="signCard" align="center">
    <mat-card style="padding: 30px;">
      <form [formGroup]="fg">
        <div class="row">
          <div class="col-12 loginForm">
            <mat-form-field appearance="outline" *ngIf="!cpfChecked">
              <mat-label>Nome Completo</mat-label>
              <input
                matInput
                type="text"
                formControlName="nome"
                required="true"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input
                matInput
                type="text"
                formControlName="cpf"
                [specialCharacters]="['/', '.', '-']"
                [mask]="'000.000.000-00'"
                required="true"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!cpfChecked">
              <mat-label>Data de Nascimento</mat-label>
              <input
                matInput
                type="text"
                formControlName="dataNasc"
                [specialCharacters]="['/']"
                [mask]="'00/00/0000'"
                required="true"
              />
              <mat-error *ngIf="this.fg.controls.dataNasc.invalid"
                >Data inválida</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!cpfChecked">
              <mat-label>Sexo *</mat-label>
              <mat-select formControlName="gender">
                <mat-option
                  *ngFor="let gender of genderArr"
                  [value]="gender.value"
                >
                  {{ gender.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!cpfChecked">
              <mat-label>Celular</mat-label>
              <input
                matInput
                type="text"
                formControlName="celular"
                [specialCharacters]="['(', ')', '-', ' ']"
                [mask]="'(00) 00000-0000'"
                required="true"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!cpfChecked">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                type="email"
                formControlName="email"
                required="true"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!cpfChecked">
              <mat-label>Senha</mat-label>
              <input
                matInput
                [type]="hidePassword ? 'password' : 'text'"
                formControlName="senha"
                required="true"
              />
              <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon
              >
            </mat-form-field>

            <!-- <mat-form-field appearance="outline">
              <mat-label>Convênio</mat-label>
              <mat-select
                formControlName="convenio"
                placeholder="Escolha um convênio"
                (selectionChange)="openModal()"
              >
                <mat-option
                  *ngFor="let convenio of convenios"
                  [value]="convenio.index"
                >
                  {{ convenio.corporateName }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div class="col-12 termsCheck" *ngIf="isAmorSaude">
            <mat-checkbox
              (change)="updateAcceptTerms($event.checked)"
            >
              <a href="https://www.amorsaude.com.br/politica-de-privacidade" target="_blank">Eu aceito os termos de privacidade</a>
            </mat-checkbox>
          </div>

          <div
            class="col"
            align="center"
            style="margin-top: 10px; margin-bottom: 8px;"
          >
            <button *ngIf="!isOnIframe"
              mat-button
              [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary btn-large': 'btn-secondary btn-large'"
              (click)="verificarDocumentoOuCadastrar()"
              [disabled]="isAmorSaude && !acceptTerms"
            >
              <span *ngIf="!isLoading; else loadingRegister">
                {{cpfChecked? 'Verificar CPF' : 'Cadastrar'}}
              </span>

              <ng-template #loadingRegister>
                <span>{{cpfChecked? ' Consultando documento ' : ''}}</span> <mat-spinner [diameter]="30" color="primary"> </mat-spinner>
              </ng-template>
            </button>

          </div>
        </div>
      </form>
      <button
          *ngIf="waitingRoomHash"
          mat-button
          class="btn-secondary btn-small back"
          (click)="goBackToWaitingRoom()"
      >
        <fa-icon icon="arrow-left"></fa-icon> Voltar
      </button>
    </mat-card>

    <mat-card *ngIf="!isOnIframe && !waitingRoomHash" align="center" class="p-margin-top-2">
      Já possui uma conta?
      <a (click)="goTo()" [ngClass]="isAmorSaude? 'amorsaudeColor': 'purple'" style="cursor: pointer;">
        <b><u>Entre aqui</u></b>
      </a>
    </mat-card>
  </div>
</div>
