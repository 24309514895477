<app-details-horario
[search]="search"
[isAmorSaude]="isAmorSaude"></app-details-horario>

<div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100" [fromRoot]="true">
  <div class="filters" *ngIf="!professionalId">
    <mat-form-field appearance="outline" [formGroup]="filter">
      <mat-label>Profissional</mat-label>
      <mat-select
        formControlName="userId"
        (selectionChange)="handleProfessionalChange()"
        [disabled]="loading"
      >
        <mat-option
          [value]="0"
        >
          Todos
        </mat-option>
        <mat-option
          *ngFor="let availableProfessional of allAvailableProfessionals"
          [value]="availableProfessional.id"
        >
          {{ availableProfessional.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="result-horarios">
    <div *ngFor="let horarioAvailable of horarios" class="result-horarios-wrapper">
      <div class="result-horarios-date" *ngIf="horarioAvailable?.values?.length">
        <mat-card [ngClass]="isAmorSaude? 'result-horarios-date-title-capitalize amorsaudeColor' : 'result-horarios-date-title-capitalize'">
          {{ horarioAvailable.date | date: 'dddd, DD/MM/YYYY':'DDMMYYYY' }}
        </mat-card>
      </div>

      <app-card-horario
        *ngFor="let horario of horarioAvailable?.values"
        [isAmorSaude]="isAmorSaude"
        [horario]="horario"
        (selectHorario)="selectHorario($event)"
      ></app-card-horario>
    </div>
    <button
      mat-button
      [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary' : 'btn-secondary'"
      (click)="onScroll()"
      *ngIf="!this.reachedDateSearchLimit"
      style="margin-top: 20px;"
    >
      <span>Mostrar mais horários</span>
    </button>
    <mat-card *ngIf="this.reachedDateSearchLimit && horarios.length !== 0"  [ngClass]="isAmorSaude? 'result-horarios-date-title amorsaudeColor' : 'result-horarios-date-title purple'" style="margin-top: 20px;">
      Não há mais horários disponíveis
    </mat-card>
    <div class="result-indisponivel" *ngIf="horarios.length == 0 && !isGettingApiResult">
      <span [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'">
        Nenhum horário encontrado para este tipo de profissional/especialidade/procedimento nessa clínica
      </span>
    </div>
  </div>
</div>
<app-loading *ngIf="isGettingApiResult && !reachedDateSearchLimit" class="p-display-block p-margin-top-2"></app-loading>
