import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  freeProcedure,
  IAgendamentoHorarioResponse,
  IAgendamentoSearch,
  IHourAvailabilityResponse,
} from 'src/app/pages/agendamentos/agendamentos.interfaces';
import { map, take, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { HorariosHelper } from 'src/app/shared/helpers/horarios.helper';
import * as moment from 'moment';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { CompanyService } from 'src/app/services/company.service';
import { SlackService } from 'src/app/services/slack.service';
import { AgendamentosService } from 'src/app/services/agendamentos.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNull } from 'lodash';
import { LoginService } from 'src/app/services/login.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IPatientResponse } from 'src/app/pages/perfil/perfil.interface';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/dialogs/confirmation/confirmation.component';
import { Params, Router } from '@angular/router';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { PartialLoginComponent } from 'src/app/shared/components/partial-login/partial-login.component';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'agendamento-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.scss'],
})
export class HorariosComponent implements OnInit {
  @Input() step: number;
  @Input() search: IAgendamentoSearch;
  @Input() patientInfo: IPatientResponse;
  @Input() queryParams: Params;
  @Input() professionalId: number = null;
  @Input() rescheduleId: number = null;
  @Output() stepChanged: EventEmitter<number> = new EventEmitter();

  public selectedDate: string;
  public horarios: IHourAvailabilityResponse[] = [];
  public allAvailableProfessionals: IAgendamentoHorarioResponse[] = [];
  private unsubscribe$: Subject<void> = new Subject();
  public isGettingApiResult: boolean = true;
  public horariosHelper = new HorariosHelper();
  private lastDay: string;
  private limitSearchDays: moment.Moment;
  private limitSearchHours: number;
  public reachedDateSearchLimit: boolean;
  private baseFormat: string = 'DDMMYYYY';
  private isCacheLoaded: boolean = false;
  public isAmorSaude: boolean = false;
  public isSchedulersView: boolean = false;
  private className: string = '';
  public loading = false;

  public filter: FormGroup = new FormGroup({
    userId: new FormControl([null]),
  });

  constructor(
    private companyService: CompanyService,
    private slackService: SlackService,
    private agendamentosService: AgendamentosService,
    private loginService: LoginService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private socket: Socket,
  ) {
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.isSchedulersView = loginService.isSchedulersView();
    this.className = this.isAmorSaude? 'amorsaude': ''
  }

  async ngOnInit() {

    if(this.search?.paciente)
      this.patientInfo = this.search.paciente;

    if(this.search.preScheduledId)
      await this.deletePreSchedule(); 

    window.scrollTo(0, 0);
    if(this.queryParams !==null && this.queryParams.preScheduledId){
      this.updatePreSchedule();
    }
    this.lastDay = this.search.scheduleDate;
    this.limitSearchDays = this.setLimitDay();
    this.limitSearchHours = this.setLimitHours();
    this.getHorarios();
  }

  private async deletePreSchedule(){
    return this.agendamentosService.deletePreSchedule( this.search.preScheduledId ).subscribe(
      res =>{
        delete this.search.preScheduledId;

        this.socket.emit('schedule-update', {
          professionalId: this.search.professionalId
        }); 

        this.snack.open('Agendamento reservado excluído com sucesso', 'ok', {duration: 3000, panelClass: this.className});
      },
      err =>{
        return;
      }
    );
  }

  private setLimitDay() {
    return moment().add(
      LocalStorageUtil.getAccountInfo().daysBeforeSchedule,
      'days',
    );
  }

  private setLimitHours() {
    return LocalStorageUtil.getAccountInfo().MinimumPrecedenceScheduleTime;
  }

  public getHorarios() {
    if (this.isReachedSearchLimit()) {
      this.reachedDateSearchLimit = true;
      return;
    }

    this.loading = true;
    this.isGettingApiResult = true;
    return this.companyService
      .getAvailability(
        this.search.procedure.id,
        this.search.occupation.id,
        this.search.occupation.expertiseId ? this.search.occupation.expertiseId.toString(): null,
        this.search.local.id,
        this.lastDay,
        this.professionalId ?? this.filter.value.userId,
        this.search.crp,
        this.isSchedulersView
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (horarios) => {
          this.loading = false;
          this.setHorarios(horarios);
        },
        error: (err) => {
          this.loading = false;
          this.slackService.slackTest(err);
        }
      });
  }

  handleProfessionalChange() {
    this.loading = true;
    this.lastDay = this.search.scheduleDate;
    this.reachedDateSearchLimit = false;
    this.horarios = [];

    const { userId } = this.filter.value;

    return this.companyService.getAvailability(
      this.search.procedure.id,
      this.search.occupation.id,
      this.search.expertise ? this.search.expertise.id : null,
      this.search.local.id,
      this.lastDay,
      userId,
      this.search.crp,
      this.isSchedulersView
    )
    .pipe(
      take(1),
    )
    .subscribe({
      next: hours => {
        this.loading = false;
        this.setHorarios(hours);
      },
      error: (err) => {
        this.loading = false;
        this.slackService.slackTest(err);
      }
    });
  }

  public setHorarios(horarios) {
    if (!this.allAvailableProfessionals.length) {
      this.allAvailableProfessionals = horarios.map(
        (hour) => ({ id: hour.id, name: hour.name })
      );
    }

    const extractClinicTimes = (availableTimes:any[]):{startTime:string, endTime:string}[] => {
      const response = []
      availableTimes?.forEach(time => {
        response.push({startTime: time?.startTime, endTime: time?.endTime})
      })
      return response
    }

    horarios.forEach(item => {
      item.clinicTimes = extractClinicTimes(item.availableTimes);
    })

    const formatted = this.horariosHelper.format(horarios, this.lastDay);

    if (!formatted.values.length) {
      this.updatePagination();
      this.getHorarios();
      return;
    }

    this.filterAvailableTimes(formatted);

    this.horarios = [...this.horarios, formatted].sort((a, b) => {
      return (
        moment(a.date, this.baseFormat).toDate().getTime() -
        moment(b.date, this.baseFormat).toDate().getTime()
      );
    });

    this.search.cachedResults = this.horarios;
    this.search.lastCachedDay = this.lastDay;
    this.isCacheLoaded = true;
    this.isGettingApiResult = false;
    this.updatePagination();
  }

  private filterAvailableTimes(
    formatted: {
      date: string,
      values: Array<{
        availableTimes: {
          startTime: string,
          [key: string]: any
        }[],
        clinicTimes: {startTime:string, endTime:string}[],
        [key: string]: any,
      }>
    }
  ): void {
      if (!formatted?.values) return;
  
      formatted.values.forEach((value) => {
          if (!value) return;
          let { clinicTimes, availableTimes } = value;
          if (!clinicTimes?.length || !availableTimes?.length) return;
  
          try {
              // Converte clinicTimes
              const clinicTimesMoment = clinicTimes.map(time => {
                  if (!time?.startTime || !time?.endTime) return null;
                  return {
                      startTime: moment(time.startTime, 'HHmm'),
                      endTime: moment(time.endTime, 'HHmm')
                  };
              }).filter(Boolean);
  
              // Valida e calcula a duração do procedimento
              const duration = this.search?.procedure?.duration;
              if (!duration) return;
              const durationInMinutes = parseInt(duration, 10);
              if (isNaN(durationInMinutes)) return;
  
              // Filtra os availableTimes
              value.availableTimes = availableTimes.filter((availableTime) => {
                  if (!availableTime?.startTime) return false;
                  
                  // Calcula o tempo final com base na duração
                  const startTimeMoment = moment(availableTime.startTime, 'HHmm');
                  if (!startTimeMoment.isValid()) return false;
                  
                  const endTimeMoment = startTimeMoment.clone().add(durationInMinutes, 'minutes');
  
                  // Verifica em qual intervalo de horário o agendamento se encaixa
                  return clinicTimesMoment.some(time => 
                      endTimeMoment.isSameOrBefore(time.endTime) && 
                      startTimeMoment.isSameOrAfter(time.startTime)
                  );
              });
          } catch (error) {
              console.error('Erro ao filtrar horários disponíveis:', error);
              value.availableTimes = [];
          }
      });
  }

  public nextStep() {
    this.step = ++this.step;
    this.stepChanged.emit(this.step);
  }

  private openDialog(hora: any, horario: any, postBody: any){
    postBody.preSchedule = true;
    const dialogRef = this.dialog.open(PartialLoginComponent, {
      disableClose: false,
      data: {
        onConfirm: (e) => {
          this.agendamentosService.insertAgendaLogout(postBody).then(
            res =>{
              this.search.preScheduledId = res.preScheduledId;

              const queryParams ={
                modalidade: this.search.modalidade.id,
                occupation: this.search.occupation.id,
                expertiseId: this.search.occupation.expertiseId,
                expertise: this.search.expertise,
                procedure: this.search.procedure.id,
                local: this.search.local.id,
                scheduleDate: this.search.scheduleDate,
                preScheduledId: this.search.preScheduledId,
                hora: hora.startTime,
                date: horario.date,
                horarioId: postBody.hourId
              }
              localStorage.setItem('queryParams', JSON.stringify(queryParams))
              window.location.reload();
            }
          ).catch(
            err=>{
              this.snack.open('Houve um erro ao realizar o pré-agendamento', 'ok', {duration:3000, panelClass: this.className});
            }
          );
        },
      },
    });
  }

  public async selectHorario({ hora, horario }: any) {
    const postBody = {
      date: horario.date,
      startHour: hora.startTime,
      procedureId: this.search.procedure.id,
      clinicId: this.search.local.id,
      professionalId: horario.id,
      preSchedule: false,
      hourId: this.search.hour && this.search.hour.id ? this.search.hour.id : hora.id,
      patientId: null,
      healthPlanId: this.search.healthPlanId || null,
      rescheduleId: this.rescheduleId ?? null,
    };
    
    if(this.search?.paciente)
      postBody.patientId = this.search.paciente.id;
    
    const hourAllowed = await this.agendamentosService.checkHourConfigurationPermission(postBody)
    .then(
      res=>{
        if(!res)
          this.snack.open(`Não é mais possível agendar para o dia selecionado, por favor, escolha um horário em um outro dia`, 'ok', {duration: 9000, panelClass: this.className});

        return !!res;
      }
    ).catch(
      err =>{
        this.snack.open('Erro ao avaliar horário', 'ok', {duration: 3000, panelClass: this.className});
        return false;
      }
    );

    if(hourAllowed){
        this.agendamentosService.checkAvailability(postBody).subscribe(
          res=>{
            if(!isNull(res.schedule))
              this.snack.open(res.schedule.message, 'ok', {duration:5000, panelClass: this.className});
            else{
              if(!this.patientInfo && !this.isSchedulersView){
                this.openDialog(hora, horario, postBody);
              }else {
                this.setSearchData( hora, horario )
                if(!hora.sameTime || this.search.procedure.onlinePayment){
                  postBody.preSchedule = true;
                  this.agendamentosService.insertAgenda(postBody).then(
                    res =>{
                      this.snack.open('Horário reservado com sucesso', 'ok', {duration:3000, panelClass: this.className})
                      this.search.preScheduledId = res.preScheduledId;
                      this.search.paymentLink = res?.paymentLink || '';

                      this.socket.emit('schedule-update', {
                        professionalId: postBody.professionalId
                      }); 

                      this.search.preScheduledId = res.preScheduledId;
                    }
                  )
                
                }
                
                this.nextStep();
              }
            }
          },
          err =>{
            this.snack.open('Erro ao avaliar disponibilidade de horário', 'ok', {duration: 3000, panelClass: this.className});
            return;
          }
        );
    }

  }

  private updatePreSchedule(){

    return this.agendamentosService.updateLogoutScheduled(this.queryParams.preScheduledId, this.queryParams.expertiseId)
    .subscribe(res=>{
      this.router.navigate([], { queryParams: {} });
      const hora = {startTime: this.queryParams.hora, id: this.queryParams.horarioId };
      const horario = { date: this.queryParams.date, ...res }
      this.search.preScheduledId = parseInt(this.queryParams.preScheduledId);
      this.search.paymentLink = res?.paymentLink || '';
      this.setSearchData(hora, horario)
      this.nextStep();
    })

  }

  public setSearchData( hora, horario ){
    this.search.scheduleDate = horario.date;
    this.search.professionalId = horario.id;
    this.search.professional = horario.name;
    this.search.professionalPhoto = horario.photo;
    this.search.document = horario.document;
    this.search.documentType = horario.documentType;
    this.search.documentUf = horario.documentUf;
    this.search.rqe = horario.rqe;
    this.search.treatmentPronoun = horario.title;
    this.search.gender = horario.gender;
    this.search.occupation.id = horario.professionalId;
    this.search.occupation.name = horario.occupation;
    this.search.hour = hora;
  }

  public onScroll() {
    if (!this.isGettingApiResult) this.getHorarios();
  }

  private isReachedSearchLimit() {
    const reached = moment(this.lastDay, this.baseFormat).isAfter(
      moment(this.limitSearchDays, this.baseFormat),
    );
    if (reached) this.isGettingApiResult = false;
    return reached;
  }

  private updatePagination() {
    this.lastDay = moment(this.lastDay, this.baseFormat)
      .add(1, 'day')
      .format(this.baseFormat);
  }

  private shouldGetFromApi() {
    if (this.search.cachedResults && this.search.cachedResults.length) {
      if (!this.isCacheLoaded) {
        this.lastDay = this.search.lastCachedDay;
        this.isCacheLoaded = true;
        this.updatePagination();
        return;
      }
    }
    return true;
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
