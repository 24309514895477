import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { AgendamentosService } from 'src/app/services/agendamentos.service';
import { AmorSaudeService } from 'src/app/services/amor-saude.service';
import { LoginService } from 'src/app/services/login.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ModalPaymentComponent } from 'src/app/shared/components/modal-payment/modal-payment.component';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { IAgendamentoSearch } from '../agendamentos/agendamentos.interfaces';
import { IPatientResponse } from '../perfil/perfil.interface';

@Component({
  selector: 'app-novoagendamento',
  templateUrl: './novoagendamento.component.html',
  styleUrls: ['./novoagendamento.component.scss'],
})
export class NovoagendamentoComponent implements OnInit {

  isAmorSaude: boolean = false;
  stopTimer: boolean = false;
  checkingPayment: boolean = false;
  loadingProfile: boolean = false;
  settingVoucher: boolean = false;
  completeBioOnly: boolean = true;
  isSchedulersView: boolean = false;
  consentCheckbox: boolean = false;
  amorSaudePaymentURL:string = '';
  className:string = '';
  patientInfo: IPatientResponse;
  subscription: Subscription;
  queryParams: Params = null;
  accountInfo;
  professionalId = null;
  rescheduleId: number = null;

  constructor(
    private agendamentosService: AgendamentosService,
    private loginService: LoginService,
    private amorSaudeService: AmorSaudeService,
    private paymentService: PaymentService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private socket: Socket,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.patientInfo = LocalStorageUtil.getPatientInfo();
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.isSchedulersView = loginService.isSchedulersView();
    this.className = this.isAmorSaude? 'amorsaude' : '';
    
    this.professionalId = route.snapshot.params['profile'];

    const queryParams = localStorage.getItem('queryParams')
    if(queryParams){
      localStorage.removeItem('queryParams');
      this.router.navigate(['/agendamentos/adicionar'], {queryParams: JSON.parse(queryParams)});
    }

    this.step = this.isSchedulersView? 0 : 1;
  
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.accountInfo = LocalStorageUtil.getAccountInfo();
    this.route.queryParams.subscribe(p => {
      if (p.rescheduleId) {
        this.rescheduleId = parseInt(p.rescheduleId);
      }
      if(p.modalidade)
        this.queryParams = p;
    })
  }

  public step: number = 1;
  public search: any = {};
  public error: string;

  public onChangeStep(currentStep: number, timesOver = false) {
    if(timesOver)
      this.dialog.closeAll();

    this.step = currentStep;
    if(currentStep == 5 && this.queryParams?.preScheduledId)
        this.step++;

  }

  public onChangeData(data: any, setPrice: boolean = true) {
    this.search = data;
    if(data.procedure && setPrice){
      this.search.price = data.procedure.value;
      this.search.voucherId = null;
    }
  }

  public onPaymentToConfirm(){

    if(this.accountInfo.showAgendaOnlineConsent && this.search.procedure.teleconsulta && !this.consentCheckbox){
      this.snack.open('Você precisa aceitar o termo de consentimento para continuar', 'ok', {duration: 3000, panelClass: this.className});
      return;
    }

    if(this.isSchedulersView && !!this.search?.procedure?.onlinePayment && !!this.search?.procedure?.value && parseFloat(this.search?.procedure?.value) > 0){
      this.stopTimer = true;
      localStorage.removeItem('preScheduledId');
      this.search.preScheduledId = null
      this.step = 9;
      return;
    }

    if((parseFloat(this.search.price) > 0 && this.search.procedure.onlinePayment && this.accountInfo.onlinePayment && !this.isSchedulersView)){

      const scheduledId = this.search.preScheduledId? this.search.preScheduledId : this.search.agendaId;

      if(!scheduledId){
        this.snack.open('Aguarde, o horário está sendo reservado','ok', { duration: 3000, panelClass: this.className });
        return;
      }

      const dialogRef = this.dialog.open(ModalPaymentComponent,{
        disableClose: true,
        panelClass: 'paymentModal',
        data:{
          scheduleId: scheduledId,
          search: this.search,
          linkedPayment: false
        }
      });

      dialogRef.afterClosed().subscribe(
        res =>{
          if(res?.amorSaudeId){
            this.checkingPayment = true;
            this.amorSaudeService.checkIfWasPayed(res?.amorSaudeId).subscribe(
              res=>{
                if(!!res){
                  this.stopTimer = true;
                  setTimeout(() => this.step = this.step + 2, 500);
                  localStorage.removeItem('preScheduledId');
                }else
                  this.router.navigate(['/agendamentos']);
  
                this.checkingPayment = false;
              },
              err=>{
                this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000, panelClass: this.className});
                this.checkingPayment = false;
              }
            )
          }else if(res.hasOwnProperty('payed')){
            this.stopTimer = true;
            setTimeout(() => this.step = this.step + 2, 500);
            localStorage.removeItem('preScheduledId');
          }else{
            this.checkingPayment = true;
            this.paymentService.verifyTransactionPayment(scheduledId).subscribe(
              res=>{
                this.checkingPayment = false;
                if( !res || res.status === 'pending' || res.status === 'failed' )
                  this.router.navigate(['/agendamentos']);
                else if(res?.status === 400){
                  this.router.navigate(['/agendamentos']);
                  this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000, panelClass: this.className});
                  this.checkingPayment = false;
                }

                this.stopTimer = true;
                setTimeout(() => this.step = this.step + 2, 500);
                localStorage.removeItem('preScheduledId');
              },
              err=>{
                this.router.navigate(['/agendamentos']);
                this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000, panelClass: this.className});
                this.checkingPayment = false;
              }
            )
          }
        }
      )
    } else{
        this.stopTimer = true;
        this.onConfirmedSchedule();
        setTimeout(() => ++this.step, 500);
    }

  }

  public onConfirmedSchedule() {

    if(this.accountInfo.showAgendaOnlineConsent && this.search.procedure.teleconsulta && !this.consentCheckbox){
      this.snack.open('Você precisa aceitar o termo de consentimento para continuar', 'ok', {duration: 3000, panelClass: this.className});
      return;
    }

    const postBody = {
      date: this.search.scheduleDate,
      startHour: this.search.hour.startTime,
      procedureId: this.search.procedure.id,
      clinicId: this.search.local.id,
      professionalId: this.search.professionalId,
      preScheduledId: this.search.preScheduledId,
      preSchedule: false,
      voucherId: this.search.voucherId,
      amount: parseFloat(this.search.price),
      hourId: this.search.hour.id,
      patientId: null,
      healthPlanId: this.search.healthPlanId || null,
      consentTermAcceptedAt: this.search.consentDate || null
    };

    if(this.search?.paciente)
      postBody.patientId = this.search.paciente.id;

    this.agendamentosService
      .insertAgenda(postBody)
      .then((response) =>{
        ++this.step
        this.search.paymentLink = response?.paymentLink || '';
        localStorage.removeItem('preScheduledId');
        this.socket.emit('schedule-update', {
          professionalId: postBody.professionalId
        })  
        
      })
      .catch((err) => {
        --this.step;
        this.error = 'Houve um erro ao criar o agendamento';
      });
  }

  public onUpdatePreSchedulePayment() {

    if(this.accountInfo.showAgendaOnlineConsent && !this.consentCheckbox){
      this.snack.open('Você precisa aceitar o termo de consentimento para continuar', 'ok', {duration: 3000, panelClass: this.className});
      return;
    }

    this.agendamentosService.updatePreSchedulePayment(this.search.preScheduledId).subscribe(
      res =>{
        ++this.step;
        this.search.paymentLink = res?.paymentLink || '';
        this.snack.open('O pagamento está sendo processado', 'ok', {duration: 5000, panelClass: this.className})
      },
      err =>{
        --this.step;
        this.error = 'Houve um erro ao criar o agendamento';
      }
    );
  }

  onSettingVoucher(e:{setVoucher: boolean, search?:IAgendamentoSearch }){
    if(e.search)
      this.onChangeData(e.search, false);

    this.settingVoucher = e.setVoucher
  }

  onConsentCheckbox(e:{consent: boolean, date: string}){
    this.consentCheckbox = e.consent;
    this.search.consentDate = e.date;
  }

  newPatientSchedule(e: {step: number, search: any}){
    this.search = e.search;
    this.onChangeStep(e.step);
  }
}
